import $ from 'jquery';
import _ from 'underscore';
import Mn from 'backbone.marionette';
import select2 from 'select2';

select2($);


var Behavior = Mn.Behavior.extend({
    onRender: function (view) {
        this.onAttach(view);
    },
    onViewAttach: function(view){
        this.onAttach(view);
    },
    onAttach: function (view) {
        setTimeout(function(){
            $(view.$el).find('[data-customselect]').each(function(){
                var $select = $(this);

                if(!$select.data('select2-init')){
                    $select.data('select2-init', true);

                    var options = $select.data('customselect');
                    var autocomplete = $select.data('autocomplete');
                    var clear = $select.data('customselect-clear');
                    var include = $select.data('customselect-include');
                    var color = ($select.parent().attr('class') || '').match(/fieldselect--color-([^\s]+)/);

                    var $obj = $select.select2(_.extend({
                        theme: 'simple',
                        dropdownCssClass: color ? color[0] : '',
                        language: {errorLoading:function(){return"Os resultados não puderam ser carregados."},inputTooLong:function(e){var n=e.input.length-e.maximum,r="Apague "+n+" caracter";return 1!=n&&(r+="es"),r},inputTooShort:function(e){return"Digite "+(e.minimum-e.input.length)+" ou mais caracteres"},loadingMore:function(){return"Carregando mais resultados…"},maximumSelected:function(e){var n="Você só pode selecionar "+e.maximum+" ite";return 1==e.maximum?n+="m":n+="ns",n},noResults:function(){return"Nenhum resultado encontrado"},searching:function(){return"Buscando…"},removeAllItems:function(){return"Remover todos os itens"}},
                        placeholder: $select.attr('placeholder') || {
                            id: '-1', // the value of the option
                            text: ' '
                          },
                        minimumResultsForSearch: autocomplete || include ? 0 : 10,
                        minimumInputLength: autocomplete || include ? 3 : undefined,
                        allowClear: !autocomplete && !!clear,
                        ajax: autocomplete ? {
                            url: function(){
                                return autocomplete;
                            },
                            dataType: 'json',
                            data: function (params) {
                                return {
                                    filter: {
                                        name: params.term,
                                    }
                                }
                            },
                        } : undefined,
                    }, options)).data('select2');


                    if(autocomplete){
                        var $clear = $('<button class="button"><span>Limpar</span></button>').hide();
                        var $results = $obj.$dropdown.find('.select2-results');

                        $results.append($clear);

                        $clear.on('click.select2clear', function(e){
                            e.preventDefault();
                            $select.val(null).trigger("change");
                            $obj.close();
                        })
                    }

                    $obj.on('open', function(param){
                        setTimeout(function () {
                            var $field = document.querySelector('.select2-search__field');

                            if($field){
                                $field.focus();
                            }
                        }, 10);

                        if(autocomplete){
                            if($obj.val() !== ''){
                                $clear.show();
                            }else{
                                $clear.hide();
                            }
                        }
                    });

                    $obj.$dropdown.data('select', $select);

                    if(include || include === ''){
                        var $include = $('<button class="button"><span>'+include+'</span></button>').hide();
                        var $results = $obj.$dropdown.find('.select2-results');
                        var $field = $obj.$dropdown.find('.select2-search__field');
                        var $name = $select.attr('name') + '_include';
                        var $hidden = $('<input type="hidden" name="'+$name+'" />');

                        $hidden.insertBefore($select);

                        $results.append($include);

                        $('body').on('keyup.select2include', $field, function(e){
                            if(e.which == 13 && $include.is(':visible')) {
                                $include.trigger('click');
                            }
                        })

                        $('body').on('click.select2include', $include, function(){
                            var $val = $field.val();

                            if($val && $val !== ''){
                                $hidden.val($val);
                                $select.append(new Option($val, 999999, true, true));
                                $obj.close();
                            }
                        })

                        $obj.on('results:message', function(param){
                            if(param.message === 'noResults'){
                                $include.show();
                            }else{
                                $include.hide();
                            }
                        })
                    }
                }
            });
        }, 500);
    }
});

export default Behavior;